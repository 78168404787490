import React, { useState } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';


function ExperienceSection() {
  const [viewed, setViewed] = useState({}); 

  return (
    <Container className="experience-section py-5" style={{marginTop:'-80px', marginBottom:'-80px', }}>
      <Row className="d-flex align-items-stretch"> {/* Add flexbox styles */}
        {[
          { end: 52, title: 'YEARS COMBINED EXPERIENCE', subtitle: 'Serving with excellence.' },
          { end: 2937, title: 'CAMPAIGNS', subtitle: 'Successful campaigns elevating brands and causes.' },
          { end: 19495, title: 'CITIES', subtitle: 'Broad geographical reach across the United States.' },
          { end: 262, title: 'PARTNERSHIPS', subtitle: 'Strong partnerships with diverse entities.' }
        ].map(({ end, title, subtitle }) => (
          <Col sm={12} md={6} lg={3} key={title}>
            <Card className="text-center border-0 h-100" > {/* Add h-100 to make the card full height */}
              <Card.Body>
                <VisibilitySensor
                  partialVisibility
                  offset={{ bottom: 200 }}
                  onChange={isVisible => {
                    if (isVisible) {
                      setViewed(prevState => ({ ...prevState, [title]: true }));
                    }
                  }}
                >
                  {({ isVisible }) => (
                    <>
                      <h3 style={{color: "#f1c40f"}}>
                        <strong>
                          <CountUp end={viewed[title] || isVisible ? end : 0} duration={3} />
                        </strong>
                      </h3>
                     <h2 style={{fontSize:'1.5rem'}}>{title}</h2>
                    </>
                  )}
                </VisibilitySensor>
               
                <Card.Text>
                  {subtitle}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <hr/>
     
    </Container>
  );
}

export default ExperienceSection;
import {  PopupButton } from 'react-calendly';
import { FaChevronDown , } from 'react-icons/fa';
import React from 'react';
import { Container, Row, Col, Card , } from 'react-bootstrap';
import Lottie from "lottie-react";
import animationData from "../assets/creative-team.json"


const SubHero2 = () => {
  return (
    <Container >
    <Row>
      <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
       
           <Lottie animationData={animationData} />
      </Col>
      <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
        <Card className="text-center mt-4 mb-4 p-3 " style={{boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}} >
          <Card.Body className="d-flex flex-column justify-content-center align-items-center ">
            <Card.Text style={{ fontSize: '25px' }}>
            Ready to boost your brand's growth? Let's discuss your marketing goals!
          
            </Card.Text>
            <p className='text-muted'>Our team will be ready to working with you.</p>
            <FaChevronDown style={{ fontSize: '2rem', display: 'block', margin: '0 auto', animation: 'bounce 2s infinite' }} />
            <PopupButton
              styles={{
                backgroundColor: '#007bff',
                borderColor: '#007bff',
                color: '#ffffff',
                padding: '0.375rem 0.75rem',
                fontSize: '1rem',
                lineHeight: '1.5',
                borderRadius: '0.25rem',
                cursor: 'pointer',
                marginTop: '1rem'  // Optional. It adds some space between the icon and the button.
              }}
              url='https://calendly.com/ndavis-2we/introductory-call'
              rootElement={document.getElementById('root')}
              text='Start Today'
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
  )
}

export default SubHero2
import React from 'react'
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import DIGITAL_BILLBOARDS from '../assets/icons8/create-new.png';
import PROGRAMMATIC_INTERNET  from '../assets/icons8/domain.png';
import EASY_AD_CREATION from '../assets/icons8/lady-window-paint-dropper.png';
import DIGITAL_RADIO_ADVERTISING from '../assets/icons8/tv-show.png';

const AdSection = () => {
  return (
    <div><Container className="ad-section"id="ad-section"  >
    <div style={{display: 'flex', justifyContent:'space-between' , margin:'10px'}}>
      <div>
        <h6> 
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-top">
                Simplify your marketing efforts with our intuitive ad creation tools. Streamline the process and save time.
              </Tooltip>
            }
          >
            <LazyLoadImage src={EASY_AD_CREATION} alt='EASY_AD_CREATION ' width={'50px'} height={'50px'}/>
          </OverlayTrigger>
          EASY AD CREATION
        </h6>
      </div>
      <div>
        <h6> 
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-top">
                Reach your target audience effectively with our radio advertising. Ensure your message lands where it matters most.
              </Tooltip>
            }
          >
            <LazyLoadImage src={DIGITAL_RADIO_ADVERTISING} alt='DIGITAL_RADIO_ADVERTISING'   width={'50px'} height={'50px'}/>
          </OverlayTrigger>
          DIGITAL RADIO ADVERTISING 
        </h6>
      </div>
      <div >
        <h6> 
          <OverlayTrigger
          
            placement="top"
            overlay={
              <Tooltip id="tooltip-top">
                Make a big impression with our digital billboards. Amplify your brand and catch the attention of potential customers.
              </Tooltip>
            }
          >
            <LazyLoadImage src={DIGITAL_BILLBOARDS} alt='DIGITAL_BILLBOARDS ' width={'50px'} height={'50px'} />
          </OverlayTrigger>
          DIGITAL BILLBOARDS
        </h6>
      </div>
      <div>
        <h6> 
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-top">
                Expand your reach with our programmatic internet advertising. Connect with customers across the web and increase conversions.
              </Tooltip>
            }
          >
            <LazyLoadImage src={PROGRAMMATIC_INTERNET} alt='PROGRAMMATIC_INTERNET' width={'50px'} height={'50px'}/>
          </OverlayTrigger>
          PROGRAMMATIC INTERNET
        </h6>
      </div>    
    </div>
  </Container></div>
  )
}

export default AdSection
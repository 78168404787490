import './App.css';
import CustomNavbar from './components/CustomNavbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CustomFooter from './components/CustomFooter';
import HomePage from './pages/HomePage';


import React from 'react';

const About = React.lazy(() => import('./pages/About'));
// const TeamPage = React.lazy(() => import('./pages/TeamPage'));
// const Team = React.lazy(() => import('./pages/Team'));
const Services = React.lazy(() => import('./pages/Services'));
const RadioAdvertisments = React.lazy(() => import('./pages/RadioAdvertisments'));
const CalendarViewEvents = React.lazy(() => import('./pages/CalendarViewEvents'));
const Founders = React.lazy(() => import('./pages/Founders'));
const Vision = React.lazy(() => import('./pages/Vision'));
const PartnersPage = React.lazy(() => import('./pages/PartnersPage'));
const NotFound = React.lazy(() => import('./pages/NotFound'));

function App() {
  return (
    <Router>
      <div>
       
          <CustomNavbar/>
          
          <React.Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/team" element={<TeamPage />} />
            <Route path="/teamnow" element={<Team />} /> */}
            <Route path="/services" element={<Services />} />
            <Route path="/radioAdvertisments" element={<RadioAdvertisments />} />
            <Route path="/calendar" element={<CalendarViewEvents />} />
            <Route path="/founders" element={<Founders />} />
            <Route path="/vision" element={<Vision />} />
            <Route path="/partners" element={<PartnersPage />} />
            <Route path="*" element={<NotFound />} /> 
          </Routes>
        </React.Suspense>
        <React.Suspense fallback={<div>Loading...</div>}>
            <CustomFooter/>
        </React.Suspense>

      </div>
    </Router>
  );
}

export default App;
import React, { useState, useRef } from 'react';
import animationData from '../assets/partnership.json'

import Slider from "react-slick";

import Lottie from "lottie-react";
import { Container, Row, Col  } from 'react-bootstrap';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';



const PartnerSection = () => {

    var settings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        arrows: true,
        dots: true,
        pauseOnHover: true,
        responsive: [{
          breakpoint: 768,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 520,
          settings: {
            slidesToShow: 3
          }
        }]
      };

    const lottieRef = useRef();
    const [loopAnim,setLoopAnim]  = useState(false);

  return (
    <section id="partners" >
         
        <div>
          <Container className="justify-content-center text-center">
            <Row>
              <Col xs={12}>
              <Container 
              onMouseEnter={() => {lottieRef.current.play(); setLoopAnim(true)}}
              onMouseLeave={() => {lottieRef.current.pause(); setLoopAnim(false)}}
            >
            <Lottie lottieRef={lottieRef} animationData={animationData} style={{ height: "350px" }} loop={loopAnim} autoplay={false} />
            </Container>
              <hr></hr>
              <p className='text-muted'>
                        PROUD COLLABORATIONS WITH RENOWNED BRANDS
                </p> 
               <h2 >Some of Our Strategic Partnerships and Clients</h2>
              <br></br>
              <Slider {...settings} className="justify-content-center text-center">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              <LazyLoadImage src="https://storage.googleapis.com/mimetic-fulcrum-375204.appspot.com/WebsiteStaticFiles/Partner-Beasley.png" alt="Partner-Beasley" style={{ width: '100px', height: 'auto', }} />
             
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LazyLoadImage src="https://storage.googleapis.com/mimetic-fulcrum-375204.appspot.com/WebsiteStaticFiles/Partner-Beasley.png" alt="Partner-Beasley" style={{ width: '100px', height: 'auto',  }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LazyLoadImage src="https://storage.googleapis.com/mimetic-fulcrum-375204.appspot.com/WebsiteStaticFiles/Partner-Wilkins.png" alt="Partner-Wilkins" style={{ width: '100px', height: 'auto', }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LazyLoadImage src="https://storage.googleapis.com/mimetic-fulcrum-375204.appspot.com/WebsiteStaticFiles/Partner-TownSquare.png" alt="Partner-TownSquare" style={{ width: '100px', height: 'auto',  }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LazyLoadImage src="https://storage.googleapis.com/mimetic-fulcrum-375204.appspot.com/WebsiteStaticFiles/Partner-TownSquare.png" alt="Partner-TownSquare" style={{ width: '100px', height: 'auto',  }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LazyLoadImage src="https://storage.googleapis.com/mimetic-fulcrum-375204.appspot.com/WebsiteStaticFiles/Partner-TownSquare.png" alt="Partner-TownSquare" style={{ width: '100px', height: 'auto',  }} />
                </div>
              </Slider> 
              </Col>
            </Row>
          </Container>
        </div>
        
      </section>
  )
}

export default PartnerSection
import { useState } from 'react';
import { Container,Row , Col, Form, Button} from 'react-bootstrap'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { useMediaQuery } from 'react-responsive'
import { toast, ToastContainer  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const EmailList = () => {
    const [email, setEmail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1024px)'
      })
  
      const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true); 
        
        try {
            if(email){
                const response = await fetch('https://crown-media-svc.herokuapp.com/weblist', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                });
        
                const data = await response.json(); 
                console.log(data, "thiis iis valueofdata")
                if (data.id.status === 'success') {
                    setEmail(""); 
                    toast.success(data.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
        
                } else if (data.id.status === 'error') {
                    throw new Error(data.id.message);
                }
                
            }
            
        } catch (error) {
            toast.error(error.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        finally {
            setIsSubmitting(false); 
        }
    };

      
  return (
    <div>     <section id="email-list" style={{ 
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: "#fff",
        padding: "60px 0",
        textAlign: 'center', 
        zIndex: 0,
      }}>
        <LazyLoadComponent>
        {isDesktopOrLaptop && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundImage: `url('https://images.unsplash.com/photo-1556761175-129418cb2dfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          opacity: 0.05,
          zIndex: -1,
        }} />
        )}
        </LazyLoadComponent>
        <div style={{ zIndex: 1, position: 'relative' }}>
          <Container>
            <Row>
              <Col xs={12}>
                <h2 style={{ fontSize: '2.5rem', fontWeight: '700', marginBottom: '20px' }}>Join Our Email List</h2>
                <p style={{ fontSize: '1.2rem', lineHeight: '1.6', marginBottom: '20px' }}>
                  Subscribe to our newsletter and stay updated on the latest developments and special offers!
                </p>
                <Form onSubmit={handleFormSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Enter your email address" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                        <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>
                    <br></br>
                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Submitting..." : "Subscribe Now"}
                    </Button>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                        />
                </Form>
              </Col>
            </Row>
           
      
          </Container>

          
        </div>
            </section></div>
  )
}

export default EmailList
import React, { useState } from 'react';
import './ServiceSection.css'
import { Container, Row, Col, Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import animationData from "../assets/paper-plane.json"
import Lottie from "lottie-react";

import Digital_Radio_Ads from '../assets/icons8/commercial.png'
import Digital_Advertisements from '../assets/icons8/man-laptop-paper-plane.png'
import Digital_Branding from '../assets/icons8/web.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';




const ServicesSection = () => {

  const [isVisible, setIsVisible] = useState(false);
  const [isStopped, setIsStopped] = useState(true);
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <VisibilitySensor
    partialVisibility
    offset={{ bottom: 200 }}
    onChange={(isVisible) => {
      setIsVisible(isVisible);
      if (isVisible) {
        setIsStopped(false);
      }
    }}
  >
    <section className="services ">
    <Container >
    <Row className="justify-content-center text-center">
          <Col xs={12}>

          <Container style={{ width: "300px", height: "200px" }}>
              <Lottie animationData={animationData} />
            </Container>
            <hr></hr>

         
            <h2 className="section-title" >
              <h6 className='text-muted'>YOU ARE ON YOUR WAY TO SUCCESS WITH </h6> Our
              <strong style={{color:'#f1c40f',backgroundColor:'#FDF6E3'}}> Marketing Solutions Tailored</strong> to your Business 
            </h2>
            

        
            
          </Col>
        </Row>
    <Row>
    <Col xs={12} sm={6} md={4} className="service-column">
        <div className="service-card">
        <LazyLoadImage src={Digital_Radio_Ads} alt='Digital Radio Ads' className="service-icon radio-icon" />
          <h3>Digital Radio Ads</h3>
          <ul className="service-list">
            <li> Amplify your brand's voice</li>
            <li> Engaging radio advertisements</li>
            <li> Reach a wider audience</li>
            <li> Create a lasting impression</li>
            <li> Drive customer actions</li>
          </ul>
          {/* <h4 className="mb-auto"style={{marginTop:'10px'}}>Starting at $500</h4> */}
          <h4 className="mb-auto"style={{marginTop:'10px'}}>.</h4>
          <Button variant="primary" className="sample-btn" onClick={() => handleButtonClick('/radioAdvertisments')} style={{marginTop:'10px'}}>Check Samples</Button>

        </div>
      </Col>
      <Col xs={12} sm={6} md={4} className="service-column">
        <div className="service-card">
        <LazyLoadImage src={Digital_Advertisements} alt='Digital_Advertisements' className="service-icon digital-icon" />
          <h3>Digital Ads</h3>
          <ul className="service-list">
            <li> Boost your online presence</li>
            <li> Targeted digital ads</li>
            <li> Attract ideal customers</li>
            <li> Increase conversions</li>
            <li> Maximize ROI</li>
          </ul>
          {/* <h4 className="mb-auto"style={{marginTop:'10px'}}>Starting at $800</h4> */}
          <h4 className="mb-auto"style={{marginTop:'10px'}}> . </h4>
          {/* <Button variant="primary" className="sample-btn" style={{marginTop:'10px'}} disabled>Check Samples</Button> */}
          <Button variant="primary" className="sample-btn" style={{marginTop:'10px'}} disabled>Soon</Button>
        </div>
      </Col>
      <Col xs={12} sm={6} md={4} className="service-column">
        <div className="service-card">
        <LazyLoadImage src={Digital_Branding} alt='Digital_Branding' className="service-icon branding-icon" />
          <h3>Digital Branding</h3>
          <ul className="service-list">
            <li> Strengthen your brand identity</li>
            <li> Comprehensive digital services</li>
            <li> Web design</li>
            <li> Social media management</li>
            <li> Stand out in the digital landscape</li>
          </ul>
          {/* <h4 className="mb-auto" style={{marginTop:'10px'}}>Starting at $1,200</h4> */}
          <h4 className="mb-auto" style={{marginTop:'10px'}}> . </h4> 
          {/* <Button variant="primary" className="sample-btn" style={{marginTop:'10px'}} disabled>Check Samples</Button> */}
          <Button variant="primary" className="sample-btn" style={{marginTop:'10px'}} disabled>Soon</Button>
        </div>
      </Col>
    </Row>
   
    
  </Container>
  <Container>

</Container>

</section>
</VisibilitySensor>


  )
}

export default ServicesSection

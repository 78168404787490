import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faHome, faEnvelope, faPhone,faPhoneSquare  } from '@fortawesome/free-solid-svg-icons';


import FB from '../assets/icons8/facebook.svg'
import IG from '../assets/icons8/instagram.svg'



export default function App() {
  return (
    
    <footer className='bg-light text-start text-lg-start text-muted garamond-font' style={{borderTop:'1px solid #f1c40f'}}>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          
          <a href='https://www.facebook.com/CrownMediaSvc' target='_blank' rel='noopener noreferrer' className='me-4 text-reset mr-3' aria-label="Crown Media on Facebook">
            <img src={FB} alt='crownmediafacebook' width={'40px'} height={'40px'}></img>
          </a>
         
          <a href='https://www.instagram.com/crownmediasvc/' target='_blank' rel='noopener noreferrer' className='me-4 text-reset mr-3' aria-label="Crown Media on Instagram">
            <img src={IG} alt='crownmediainstagram' width={'40px'}height={'40px'}></img>
          </a>
        
        </div>
      </section>

      <section className=''>
        <Container className='text-start text-md-start mt-5'>
          <Row className='mt-3'>
            <Col md='3' lg='4' xl='3' className='mx-auto mb-4'>
            <iframe border="0"  style={{ border: "0", height: "73px", width: "350px"}} src="https://seal-westernmichigan.bbb.org/frame/blue-seal-293-61-bbb-38287660.png?chk=6147A43126"></iframe>

              <h6 className='text-uppercase fw-bold mb-4'>
               
                Crown Media Service
              </h6>
              <p>
                We provide cutting-edge solutions and innovative strategies to help your brand shine in the digital world.
                Let us transform your media presence and take your brand to new heights.
              </p>
            </Col>

            <Col md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
              <p>
                <a href='/radioAdvertisments' className='text-reset'>
                  Radio Advertisements
                </a>
              </p>
              <p>
                <a href='/' className='text-reset'>
                  Digital Advertisements
                </a>
              </p>
              <p>
                <a  href='/' className='text-reset'>
                  Digital Services & Branding
                </a>
              </p>
              {/* <p>
                <a href='#!' className='text-reset'>
                  IT Business Solutions
                </a>
              </p> */}
            </Col>

            <Col md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='/partners' className='text-reset'>
                  Our Team and Partners
                </a>
              </p>
              <p>
                <a href='/services' className='text-reset'>
                  Our Personalized Services
                </a>
              </p>
              <p>
                <a href='/calendar' className='text-reset'>
                  Upcoming Campaigns
                </a>
              </p>
              {/* <p>
                <a href='#!' className='text-reset'>
                  Schedule a Consultation
                </a>
              </p> */}
            </Col>

            <Col md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4 '>Contact</h6>
              <p>
                <FontAwesomeIcon color='secondary' icon={faHome} className='me-3 mr-2' />
                <a href="https://www.google.com/maps/search/?api=1&query=110+Elm+Street,+Big+Rapids,+Michigan+49307,+United+States" target="_blank" rel="noopener noreferrer" className='text-muted'>
                    110 Elm Street, Big Rapids, Michigan 49307, United States
                </a>
              </p>
              <p>
                <FontAwesomeIcon color='secondary' icon={faEnvelope} className='me-3 mr-2' />
                <a href="mailto:Info@crownmediasvc.com" target="_blank" rel="noopener noreferrer" className='text-muted'>
                    Info@crownmediasvc.com
                </a>
              </p>
              <p>
                  <FontAwesomeIcon color='secondary' icon={faPhoneSquare } className='me-3 mr-2' />
                  Michigan Office: <a href="tel:2314085590" className='text-muted' >231-408-5590</a><br/>
                  <FontAwesomeIcon color='secondary' icon={faPhone} className='me-3 mr-2' />
                  Toll Free: <a href="tel:8775050098" className='text-muted'>877-505-0098</a> 
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © {new Date().getFullYear()} Crown Media Service LLC. All rights reserved.
      </div>
    </footer>
  );
}

{/* <FontAwesomeIcon icon={faPhoneRotary} /> */}
import React from 'react'
import { Container ,Row , Col} from 'react-bootstrap'
import { FaChevronCircleDown } from 'react-icons/fa';

import animationData from "../assets/marketing.json"
import Lottie from "lottie-react";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { useMediaQuery } from 'react-responsive';

const SubHero = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1024px)'
      })
  
      
  return (
    <section id="about-us" style={{ 
        position: 'relative',
        overflow: 'hidden',
        padding: "60px 0",
        zIndex: 0,
      }}>
        {isDesktopOrLaptop && ( <LazyLoadComponent>
                <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                backgroundImage: `url('https://images.unsplash.com/photo-1605106702734-205df224ecce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWgelHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                opacity: 0.05,
                zIndex: -1,
                }} />
            </LazyLoadComponent>)
           
           }
        <div style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: '70%',
          width: '100%',
          background: 'linear-gradient(to top, rgba(249, 249, 249, 1), rgba(249, 249, 249, 0))',
          zIndex: 1,
        }} />
        <Container style={{ zIndex: 2, position: 'relative' }}>
        <Row className="justify-content-center ">
          <Col xs={12} md={6}>  
                <Lottie animationData={animationData} />
                
                <h2 style={{ fontWeight: '700', marginBottom: '20px' }}>
                    We are not just about creating a buzz, <strong style={{color: "#f1c40f"}}>we're about creating an impact.</strong>
                  </h2>
              <Container>
                <FaChevronCircleDown style={{ fontSize: '2rem', display: 'block', margin: '0 auto', animation: 'bounce 2s infinite' }} />
      
              </Container>    
          </Col>
      
          </Row>
          </Container>
         
          </section>
  )
}

export default SubHero
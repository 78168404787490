import React, { useState, useEffect, useCallback  } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { useMediaQuery } from 'react-responsive'

import heroImage from '../assets/Optimized-Hero-Small-Business-Search.webp'

const MainHero = () => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1024px)'
      })

    const words = [
        'Amplify Your Message.',
        'Boost Your Brand Visibility.',
        'Get Heard, Be Recognized.',
        'Reach Your Target Audience.',
        // 'Unleash Your Brand Potential.',
        'Connect, Engage, Succeed.'
      ];
    
      const [currentText, setCurrentText] = useState('');
      const [isDeleting, setIsDeleting] = useState(false);
      const [textIndex, setTextIndex] = useState(0);
      const [charIndex, setCharIndex] = useState(0);

      useEffect(() => {
        const videoLink = "https://storage.googleapis.com/mimetic-fulcrum-375204.appspot.com/WebsiteStaticFiles/Local-Business-Startup-Marketing-Solutions-Hero.av1.mp4";

        if (!isDesktopOrLaptop) {
       
          const link = document.createElement("link");
          link.rel = "preload";
          link.as = "image";
          link.href = heroImage;
          document.head.appendChild(link);
    
          return () => {
            document.head.removeChild(link);
          };
        } else {
          const link = document.createElement("link");
          link.rel = "preload";
          link.as = "video";
          link.href = videoLink;
          document.head.appendChild(link);
    
          return () => {
            document.head.removeChild(link);
          };
        }
      }, []);

      
    
  useEffect(() => {
    const interval = setInterval(() => {
      if(isDeleting){
        if(charIndex > 1){
          setCurrentText(words[textIndex].substring(0, charIndex-1));
          setCharIndex(charIndex - 1);
        } else {
          setIsDeleting(false);
          setTextIndex((textIndex+1) % words.length);
          setCharIndex(0);
        }
      } else {
        setCurrentText(words[textIndex].substring(0, charIndex+1));
        setCharIndex(charIndex + 1);

        if(charIndex === words[textIndex].length){
          setIsDeleting(true);
          setTimeout(() => clearInterval(interval), 1500);
        }
      }
    }, isDeleting ? 50 : 100);

    return () => clearInterval(interval);
  }, [charIndex, isDeleting, textIndex, words]);

  const scrollToAdSection = useCallback(() => {
    const adSection = document.getElementById('ad-section');
    window.scrollTo({
      top: adSection.offsetTop,
      behavior: 'smooth'
    });
  }, []);

  return (
    <section className="hero" style={!isDesktopOrLaptop ? { backgroundImage: `url(${heroImage})` } : {}}>
    {/* <section className="hero" style={!isDesktopOrLaptop ? { background:'#F5F5F5' } : {}}> */}
      
       {isDesktopOrLaptop && (
       
           <video autoPlay muted loop className="hero-video">
             <source src="https://storage.googleapis.com/mimetic-fulcrum-375204.appspot.com/WebsiteStaticFiles/Local-Business-Startup-Marketing-Solutions-Hero.av1.mp4" type="video/mp4" />
             Your browser does not support the video tag.
           </video>
         
          )
         }


       
       <Container>
         <Row>
           <Col md={6}>
             <div className="hero-content">
             <div className="typed-card" >
                 <h2 style={{color:"#f8f9fa"}}>
                 <p>
                   <br/>
                   <br/>
                   
                   </p>
                   <div dangerouslySetInnerHTML={{ __html: currentText }} />
                 </h2>
                 <p>
                   <br/>
                   Broadcasting community Initiatives. <br/>
                   Empowering Communities, Transforming Businesses.
                   <br/>
                   <br/>
                   Welcome to <strong style={{color:'#f1c40f'}}>Crown Media Service.</strong>
                   
                 </p>
                 <br/>
                 <Button variant="outline-light" className="cta-btn"  onClick={scrollToAdSection}>
                   Get Started <AiOutlineArrowRight />
                 </Button>
               </div>
             </div>
           </Col>
         </Row>
       </Container>

     </section>
  )
}

export default MainHero
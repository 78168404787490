import React, { useState } from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {  PopupButton } from 'react-calendly';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';
import {  faPhone } from '@fortawesome/free-solid-svg-icons';

import LogoSVG2 from '../assets/Crown-Media-Logo-Main-Logo.svg'

function NavDropdownComponent({ title, path, children }) {
  const linkStyle = useActiveLinkStyle(path);
  return (
    <NavDropdown title={title} id={`${title}-dropdown`} style={linkStyle}>
      {children}
    </NavDropdown>
  );
}

function useActiveLinkStyle(path) {
  const location = useLocation();
  const isActive = location.pathname === path; // changed from startsWith to ===
  return isActive
    ? { color: '#f1c40f', textDecoration: 'none', fontSize: '1.1rem' , backgroundColor:'#383838' }
    : { color: '#6c757d', fontSize: '1rem' };
}

export default function App() {
  const [showBasic, setShowBasic] = useState(false);

  const closeMenu = () => {
    setShowBasic(false);
  };

  return (
    <Navbar expand='lg' bg='light' variant='light' sticky='top' style={{ boxShadow: "0 2px 2px -2px gray", fontSize:'Extra-Large' , borderBottom:'1px solid #f1c40f' }} className='text-muted garamond-font'>

     
      
      <Container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className='custom-nav'>
        
      <Navbar.Brand as={Link} to='/' className="logo-brand" style={{ padding: '0 1rem' }}>
          <img
            src={LogoSVG2}
            alt='Crown-Media-Service'
            style={{ maxHeight: '62.5px', height:'62.5px',width:'250px' }}
          />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowBasic(!showBasic)}
        >
          <FontAwesomeIcon icon={faBars} />
        </Navbar.Toggle>

        <Navbar.Collapse in={showBasic}>
          <Nav className='mr-auto custom-nav' style={{ padding: '0 1rem' }}>
          <Nav.Link as={NavLink} exact to='/' style={useActiveLinkStyle('/')} onClick={closeMenu}>
              HOME
            </Nav.Link>
            <Nav.Link as={NavLink} exact to='/services' style={useActiveLinkStyle('/services')} onClick={closeMenu}>
              SERVICES
            </Nav.Link>
            <Nav.Link as={NavLink} exact to='/calendar' style={useActiveLinkStyle('/calendar')} onClick={closeMenu}>
              UPCOMING CAMPAIGNS
            </Nav.Link>

            <NavDropdownComponent title='ABOUT US'  className='my-dropdown'>
              {/* <NavDropdown.Item as={NavLink} to='/teamnow' style={useActiveLinkStyle('/teamnow')}>
                OUR TEAM
              </NavDropdown.Item> */}
              <NavDropdown.Item as={NavLink} to='/founders' style={useActiveLinkStyle('/founders')} onClick={closeMenu}>
                FOUNDERS
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to='/vision' style={useActiveLinkStyle('/vision')} onClick={closeMenu}>
                VISION
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to='/partners' style={useActiveLinkStyle('/partners')} onClick={closeMenu}>
                PARTNERS
              </NavDropdown.Item>
            </NavDropdownComponent>


            <Nav.Link as={NavLink} to='tel:877-505-0098' style={useActiveLinkStyle('/contact')}>
              <FontAwesomeIcon color='secondary' icon={faPhone} className='me-3' /> 877-505-0098
            </Nav.Link>
          </Nav>
         
          <Nav>
            <PopupButton
              styles={{
                backgroundColor: '#007bff',
                borderColor: '#007bff',
                color: '#ffffff',
                padding: '0.375rem 0.75rem',
                fontSize: '1rem',
                lineHeight: '1.5',
                borderRadius: '0.25rem',
                cursor: 'pointer',
              }}
              url='https://calendly.com/ndavis-2we/introductory-call'
              rootElement={document.getElementById('root')}
              text='Schedule Consultation'
            />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
import React , {Suspense} from 'react';
import { Container, Spinner} from 'react-bootstrap';
import './HomePage.css';
import ServicesSection from '../components/ServicesSection';
import ExperienceSection from '../components/Experience';
import PartnerSection from '../components/PartnerSection';
import CustomerComments from '../components/CustomerComments';
import SubHero from '../components/SubHero';
import SubHero2 from '../components/SubHero2';
import AdSection from '../components/adSection';
import EmailList from '../components/EmailList';
import MainHero from '../components/MainHero';
import { useMediaQuery } from 'react-responsive';




const HomePage = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1024px)'
  })
  
  return (
    <div className="homepage">
     
     <MainHero/>

      <Suspense fallback={ <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>}>
        <div className="section-divider"></div>
        {isDesktopOrLaptop && (
        <AdSection/>
      )}

        {isDesktopOrLaptop && (
        <div className="section-divider" ></div>)}
        <SubHero/>
        <ExperienceSection/>
        <ServicesSection/>
        {isDesktopOrLaptop && <PartnerSection/>}
        {/* <CustomerComments/> */}
        <SubHero2/>
        <Container fluid  >
          <EmailList/>
        </Container>
      </Suspense>
    
    
    </div>
  );
};

export default HomePage;